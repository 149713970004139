import styled, { css } from 'styled-components'
import { BlockPadding } from '../graphql/contentfulTypes'

export const handlePaddingTop = ({ paddingTop }: BlockPadding) =>
  paddingTop
    ? css`
        padding-top: 48px;
      `
    : ''

export const handlePaddingBottom = ({ paddingBottom }: BlockPadding) =>
  paddingBottom
    ? css`
        padding-bottom: 20px;
      `
    : ''

export const handleBlockPadding = {
  top: handlePaddingTop,
  bottom: handlePaddingBottom,
}

export const SimpleBlockWrapper = styled.div<{ backgroundColor?: string } & BlockPadding>`
  width: 100%;
  background: ${({ backgroundColor }) => backgroundColor || ''};
`
