import React from 'react'
import styled from 'styled-components'
import { breakpoints, hexToRGBA, toCssPrefix } from '@ecommerce/shared'
import { PromoCardProps } from './common'

type Props = PromoCardProps & { currencySymbol: string; clickable?: boolean }

const { cssPrefix, toPrefix } = toCssPrefix('PromoCard__')

const Wrapper = styled.div<{ bg: string; clickable?: boolean }>`
  height: 220px;
  padding: 25px 18px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-image: ${({ bg }) => `url('${bg}')`};
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  position: relative;

  .${cssPrefix} {
    &pre-title {
      font-size: 14px;

      &.has-currency {
        font-size: 20px;
        position: relative;
        bottom: -3px;
      }
    }

    &description {
      font-size: 18px;
      max-width: 150px;
      line-height: 22px;
      margin: 0;
    }

    &info-text {
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: ${({ theme }) => hexToRGBA(theme.colors.black60, 0.5)};
      margin: 0;
      font-size: 12px;
      text-align: center;
      height: 25px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
    }
  }

  @media (${breakpoints.tabletPortrait.min}) {
    width: 100%;
  }
`

const CardTitle = styled.div<{ textType: string }>`
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 40px;
  line-height: 45px;
  margin-bottom: 16px;
  position: relative;
  width: fit-content;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    width: 80px;
    background: ${({ theme }) => theme.colors.white};
    bottom: -5px;
    left: 0;
  }

  .currency-symbol {
    font-size: 30px;
    line-height: 40px;
  }

  .discount {
    margin: 0;
    line-height: 22px;
    position: relative;
    top: -6px;

    &,
    span {
      display: block;
    }

    .discount-label {
      font-size: 10px;
    }

    .discount-symbol {
      font-size: 35px;
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    font-size: ${({ textType }) => (textType === 'discount' ? '65px' : '40px')};
    line-height: ${({ textType }) => (textType === 'discount' ? '65px' : '40px')};

    &::after {
      width: 120px;
    }

    .discount {
      line-height: 26px;
      top: -9px;

      .discount-label {
        font-size: 12px;
      }

      .discount-symbol {
        font-size: 44px;
      }
    }
  }
`

const PromoCardLarge = ({ data, onCardClick, currencySymbol, className, ...restProps }: Props) => {
  const { titleType, title, subtitle, description, infoText } = data
  const hasDiscount = titleType === 'discount'
  const isCurrency = titleType === 'price'

  const onClick = () => (onCardClick ? onCardClick(data) : null)

  return (
    <Wrapper
      {...restProps}
      onClick={onClick}
      data-test="promo-card-large"
      bg={`${data.image}?q=90`}
      className={`${cssPrefix} ${className}`}
    >
      {subtitle && <span className={toPrefix(`pre-title ${isCurrency ? 'has-currency' : ''}`)}>{subtitle}</span>}
      {title && (
        <CardTitle textType={titleType}>
          {isCurrency && !hasDiscount ? <span className="currency-symbol">{currencySymbol}</span> : null}
          {title}
          {hasDiscount && !isCurrency ? (
            <span className="discount">
              <span className="discount-label">DCTO</span>
              <span className="discount-symbol">%</span>
            </span>
          ) : null}
        </CardTitle>
      )}
      {/* eslint-disable react/no-danger */}
      <h3 className={toPrefix('description')} dangerouslySetInnerHTML={{ __html: description }} />
      {infoText && <p className={toPrefix('info-text')}>{infoText}</p>}
    </Wrapper>
  )
}

export default PromoCardLarge
