import React from 'react'
import styled, { css } from 'styled-components'
import loadable from '@loadable/component'
import { Swiper, SwiperSlide } from 'swiper/react'
import { toCssPrefix, breakpoints, useLocation, i18n } from '@ecommerce/shared'
import { Promo } from './common'
import PromoCardSkeleton from '../Skeletons/PromoCardSkeleton'
import { TagManagerEventData } from '../../graphql/contentfulTypes'
import PromoCardLarge from './PromoCardLarge'
import { Icon } from '../Icon/Icon'
import SectionTitle from '../Moments/SectionTitle'

export type PromoCardType = 'promo-small' | 'promo-large'

type Props = {
  isLiqueursLanding?: boolean
  items: (Promo & TagManagerEventData)[]
  isLoading: boolean
  title?: string
  titleType?: 'h1' | 'h2'
  titleColor?: string
  titleBorderColor?: string
  titleBorderColorShadowNeon?: string
  centerTitle?: boolean
  type?: PromoCardType
  onCardClick?: (promo: Promo & TagManagerEventData, e?: React.MouseEvent | React.TouchEvent, idx?: number) => void
  className?: string
}

const PromoCardSmall = loadable(() => import('./PromoCardSmall'))

const { cssPrefix, toPrefix } = toCssPrefix('PromoListSlider__')

export const getBorder = (borderColor?: string, borderColorNeon?: string) => {
  let border
  let borderShadow
  if (borderColor) {
    border = css`
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - 50px);
      width: 100px;
      height: 4.8px;
      border-radius: 8px;
      background: ${borderColor};
    `
  }
  if (borderColorNeon) {
    borderShadow = css`
      box-shadow: 0 0 5px 3px ${borderColorNeon};
      border: 1px solid ${borderColorNeon};
    `
  }
  return css`
    &::after {
      ${border}${borderShadow}
    }
  `
}

const Wrapper = styled.div<{
  allowTitleLowerCase?: boolean
  centerTitle?: boolean
  titleBorderColor?: string
  titleBorderColorShadowNeon?: string
  titleColor?: string
}>`
  width: 100%;
  position: relative;

  .swiper {
    &-button {
      &-next,
      &-prev {
        display: none;
        opacity: 0.5;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.red};
        border-radius: 50%;
        height: 40px;
        width: 40px;
        transition: 200ms;
        &::after {
          font-weight: bolder;
          font-size: 18px;
        }
        &:hover {
          opacity: 0.5;
          background-color: ${({ theme }) => theme.colors.red};
        }
      }
    }
  }

  .${cssPrefix} {
    &title {
      margin: 0 0 38px 25px;
      position: relative;
      color: ${({ titleColor }) => titleColor || 'inherit'};
      text-align: ${({ centerTitle }) => (centerTitle ? 'center' : 'left')};
    }

    &slide {
      width: fit-content;
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .swiper {
      &-button {
        &-next,
        &-prev {
          display: flex;
        }
      }
    }

    .${cssPrefix} {
      &navigation {
        display: flex;
      }
    }
  }
`

export const PromoCardWrapper = styled.div<{ type: string }>`
  width: 340px;
  height: auto;
  padding: 0;
  margin-right: 25px;

  &:first-child {
    width: 320px;
    padding: 0 0 0 20px;
  }

  @media (${breakpoints.tabletPortrait.min}) and (${breakpoints.tabletPortrait.max}) {
    width: ${({ type }) => (type === 'small' ? '367px' : '380px')};

    &:first-child {
      width: ${({ type }) => (type === 'small' ? '377px' : '320px')};
    }
  }

  @media (${breakpoints.desktop.min}) {
    width: 367px;
    padding: 0;

    &:first-child {
      width: 367px;
      padding: 0;
    }
  }
`

const PromosList = ({
  className,
  isLiqueursLanding,
  isLoading,
  items,
  onCardClick,
  type,
  title,
  titleType,
  titleColor,
  ...props
}: Props) => {
  const cardType = type ?? 'promo-large'

  const { textByCountry } = useLocation()
  const currencySymbol = textByCountry(i18n.CL.currencySymbol, i18n.BO.currencySymbol)

  return (
    <Wrapper className={className} {...props}>
      {isLiqueursLanding && (
        <Icon
          className={`${toPrefix('title')} ${className}-title`}
          sizeWidth={175}
          sizeHeight={60}
          iconId="logo-liquors"
        />
      )}
      {!isLiqueursLanding && title && (
        <SectionTitle
          type={titleType}
          text={title}
          color={titleColor}
          className={`${toPrefix('title')} ${className}-title`}
          {...props}
        />
      )}

      <Swiper navigation slidesPerView="auto" className="swiper">
        {isLoading || items.length === 0 ? (
          <>
            {Array(9)
              .fill(0)
              .map((_, i) => (
                <SwiperSlide key={i} className={toPrefix('slide')}>
                  <PromoCardWrapper type={cardType} key={i}>
                    <PromoCardSkeleton height={cardType === 'promo-small' ? 220 : undefined} />
                  </PromoCardWrapper>
                </SwiperSlide>
              ))}
          </>
        ) : (
          <>
            {items.map((promo, idx) => (
              <SwiperSlide key={idx} className={toPrefix('slide')}>
                <PromoCardWrapper type={cardType} key={promo.id}>
                  {cardType === 'promo-small' ? (
                    <PromoCardSmall
                      onCardClick={(promotion, e) => (onCardClick ? onCardClick(promotion, e, idx) : null)}
                      data={promo}
                      className={`${className}-card card-small`}
                    />
                  ) : (
                    <PromoCardLarge
                      currencySymbol={currencySymbol}
                      onCardClick={(promotion, e) => (onCardClick ? onCardClick(promotion, e, idx) : null)}
                      data={promo}
                      clickable={!!onCardClick}
                      className={`${className}-card card-large`}
                    />
                  )}
                </PromoCardWrapper>
              </SwiperSlide>
            ))}
          </>
        )}
      </Swiper>
    </Wrapper>
  )
}

export default PromosList
