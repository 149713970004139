import { breakpoints } from '@ecommerce/shared/src/utils/breakpoints'
import React from 'react'
import styled, { css } from 'styled-components'
import { BlockTitle } from '../../../graphql/contentfulTypes'

type Props = React.HTMLAttributes<HTMLHeadingElement> &
  Omit<BlockTitle, 'title' | 'titleColor'> & {
    type?: 'h1' | 'h2'
    color?: string
    centerTitle?: boolean
    titleBorderColor?: string
    titleBorderColorShadowNeon?: string
    allowTitleLowerCase?: boolean
  }

const commonStyles = css<Omit<Props, 'type'>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  font-weight: bold;
  align-items: ${({ centerTitle }) => (centerTitle ? 'center' : 'justify-start')};
  font-size: ${({ titleSize }) => titleSize || '24px'};
  line-height: 29px;
  position: relative;
  //padding: 0 0 24px;
  margin: 0;
  //background: red;
  color: ${({ color }) => color || 'inherit'};
  ${({ allowTitleLowerCase }) =>
    allowTitleLowerCase &&
    css`
      text-transform: uppercase;
    `}

  @media screen and (${breakpoints.desktop.min}) {
    font-size: ${({ titleSizeDesktop }) => titleSizeDesktop || '32px'};
    gap: 24px;
  }
`

const Border = styled.div<Omit<Props, 'type'>>`
  display: block;
  left: ${({ centerTitle }) => (centerTitle ? 'calc(50% - 50px)' : 0)};
  width: 100px;
  height: 4px;
  border-radius: 8px;
  @media screen and (${breakpoints.desktop.min}) {
    font-size: ${({ titleSizeDesktop }) => titleSizeDesktop || '32px'};
  }

  ${({ titleBorderColor }) =>
    titleBorderColor &&
    css`
      background: ${titleBorderColor};
    `}
  ${({ titleBorderColorShadowNeon }) =>
    titleBorderColorShadowNeon &&
    css`
      box-shadow: 0 0 5px 3px ${titleBorderColorShadowNeon};
      border: 1px solid ${titleBorderColorShadowNeon};
    `}
`

const StyledH1 = styled.h1<Omit<Props, 'type'>>`
  ${commonStyles}
`

const StyledH2 = styled.h2<Omit<Props, 'type'>>`
  ${commonStyles}
`

interface StyledTitleProps extends Props {
  children: React.ReactNode
}

interface SectionTitleProps extends Props {
  text: string
}

const StyledTitle: React.FC<StyledTitleProps> = ({ type = 'h2', children, ...restProps }) => {
  const Component = type === 'h1' ? StyledH1 : StyledH2
  return <Component {...restProps}>{children}</Component>
}

const SectionTitle: React.FC<SectionTitleProps> = ({ className, text, type, ...restProps }) => {
  return (
    <StyledTitle type={type} {...restProps} className={className}>
      {text}
      <Border {...restProps} />
    </StyledTitle>
  )
}

export default SectionTitle
