import React from 'react'
import styled from 'styled-components'
import { breakpoints, skeletonAnimationStyles, ZIndex } from '@ecommerce/shared'

type Props = {
  height?: number
}

const Wrapper = styled.div<Props>`
  width: 310px;
  height: ${({ height }) => (height ? `${height}px` : '107px')};
  padding: 0;
  position: relative;
  float: left;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  background: ${({ theme }) => theme.colors.white};
  z-index: ${ZIndex.medium};
  opacity: 1;

  &:first-child {
    width: 320px;
    padding: 0 0 0 10px;
  }

  @media (${breakpoints.tabletPortrait.min}) {
    width: 367px;
    height: ${({ height }) => (height ? `${height}px` : '125px')};
    &:first-child {
      width: 377px;
    }
  }
  @media (${breakpoints.desktop.min}) {
    width: 367px;
    padding: 0 0 0 0;
    &:first-child {
      width: 367px;
      padding: 0 0 0 0;
    }
  }

  .animated-background {
    ${skeletonAnimationStyles}
    position: relative;
    width: 100%;
    height: 100%;
  }
`

const PromoCardSkeleton = ({ height }: Props) => {
  return (
    <Wrapper height={height}>
      <div className="animated-background" />
    </Wrapper>
  )
}

export default PromoCardSkeleton
