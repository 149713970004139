import { Document } from '@contentful/rich-text-types'
import { Moment, PageCommonData } from '../types/PgPages'

export enum GoogleTagManagerEventType {
  DEFAULT = 'default',
  PROMOTION = 'promotion',
}

export type TagManagerEventData = {
  googleTagManagerEventType?: GoogleTagManagerEventType
  googleTagManagerEventValue?: string
}

export type RichTextJson = {
  json: Document
}

export interface ContentfulImage {
  id: string
  file: {
    url: string
  }
}

export enum BlockType {
  PROMO_SMALL = 'promo-small',
  PROMO_LARGE = 'promo-large',
  CATEGORY = 'category',
  PRODUCT = 'product',
  BRAND = 'brand',
  SLIDE = 'slide',
  MOMENT_CARD = 'moment-card',
  HERO = 'hero',
  PROMO_XL_GRID = 'promo-xl-grid',
  FILTERED_LIST = 'filtered-list',
  RECIPE = 'recipe',
  BRANDS_LIST = 'brands-list',
  PRODUCT_SLIDE = 'product-slide',
  VIDEO = 'video',
  NEWS = 'news',
  BANNER_SLIDER = 'banner-slider',
  HEADING = 'heading',
  COUNTDOWN = 'countdown',
  FAVORITES = 'favorite-products',
  TEXT_WITH_ICON = 'text-with-icon',
  ESTABLISHMENT = 'establishment',
  CONTACT_FORM = 'contact-form',
  RIBBON_MESSAGES = 'ribbon-messages',
  HOW_IT_WORKS = 'how-it-works',
  BANNER_FLUID = 'banner-fluid',
  SINGLE_VIDEO = 'single-video',
  EVENT_QUOTE = 'event-quote',
  RECIPE_SLIDE = 'recipe-slide',
}

export type BlockPadding = {
  paddingTop?: boolean
  paddingBottom?: boolean
}

export type BlockTitle = {
  title?: string
  titleType?: 'h1' | 'h2'
  titleSize?: '24px' | '32px'
  titleSizeDesktop?: '24px' | '32px'
  titleColor?: string
  centerTitle?: boolean
  allowTitleLowerCase?: boolean
  titleBorderColor?: string
  titleBorderColorShadowNeon?: string
}

export type BaseBlock = {
  name: string
  paddingTop?: boolean
  paddingBottom?: boolean
  blockType: BlockType
  actionSlug?: string
  background?: {
    url: string
  }
  nameList?: string[]
  backgroundColor?: string
  brandShadow?: boolean
  sys: {
    id: string
  }
} & BlockPadding &
  BlockTitle

export interface Block<T = any> extends BaseBlock {
  contentful_id: string
  paddingTop?: boolean
  backgroundColor?: string
  items: T[]
  allowTitleLowerCase?: boolean
}

export interface ClientSideBlock<T = any> extends BaseBlock {
  itemsCollection: {
    items: T[]
  }
}

export interface ClientSideBlockQueryResult<T = any> {
  orList: ClientSideBlock<T>
}

export type Hero = {
  id: string
  text: string
  textColor?: string
  image: ContentfulImage
  heroType: 'simple' | 'with-cta' | 'with-cta-no-padding'
  callToActionImageDesktop: ContentfulImage
  callToActionImageTablet: ContentfulImage
  callToActionImageMobile: ContentfulImage
  buttonText?: string
  buttonSlug?: string
  backgroundColor?: string
}

export type BuildTimePromo = {
  id: string
  actionSlugLong?: { actionSlugLong: string }
  image: ContentfulImage
  promoDescription: string
  promoSubtitle: string
  promoTitle: string
  titleType: 'discount' | 'price' | 'text'
} & TagManagerEventData

export type PromoXL = {
  id: string
  actionSlug?: string
  actionSlugLong?: {
    actionSlugLong?: string
  }
  imageDesktop: ContentfulImage
  imageTablet: ContentfulImage
  imageMobile: ContentfulImage
}

export type Filter = {
  label: string
  filter: string
}

export type FilterProduct = {
  sku: string
  categoryName: string
}

export type Recipe = {
  id: string
  contentful_id: string
  sliderTitle: string
  sliderDescription: string
  sliderImage: ContentfulImage
  slug: string
  parentMoment?: Pick<PageCommonData, 'navbar' | 'theme' | 'title' | 'slug'>
  metaTitle: string
  metaKeywords: string[]
  metaDescription: string
  detailTitle: string
  preparationVideo: ContentfulImage
  preparationVideoPoster: ContentfulImage
  preparationVideoTitle: string
  preparationVideoDescription: string
  steps: string[]
  stepsImages: ContentfulImage[]
  aboutTitle: string
  aboutText: string
  ingredientsTitle: string
  ingredients: string[]
  preparationTitle: string
  preparationText: string
  relatedProductsTitle: string
  relatedProducts: {
    sku: string
  }[]
  relatedRecipesTitle: string
  relatedRecipes: Recipe[]
  socialMediaShareMessage: string
  backgroundColorCard?: string
  textColorCard?: string
  backgroundColorBody?: string
  backgroundImageBody?: ContentfulImage
}

export type BrandsList = {
  id: string
  brands: ContentfulImage[]
  background: ContentfulImage
}

export type ProductSlide = {
  id: string
  subtitle?: string
  title: string
  showPrice: boolean
  description?: RichTextJson
  image: ContentfulImage
  showAddButton: boolean
  showDetailsButton: boolean
  product: {
    sku: string
  }
  bodyColor?: string
  priceColor?: string
  originalPriceColor?: string
  addSpace?: boolean
}

export type Video = {
  contentful_id: string
  title: string
  videoDescription: string
  video: ContentfulImage
  videoPoster: ContentfulImage
}

export type NewsType = {
  title: string
  imageAlignment: string
  text: RichTextJson
  descriptionImage?: ContentfulImage | null
  descriptionVideo?: ContentfulMLVideo | null
}

export type NewsItem = {
  contentful_id: string
  metaTitle: string
  metaDescription: string
  metaKeywords: string[]
  sliderTitle: string
  sliderDescription: string
  sliderRichTextDescription: RichTextJson
  sliderImage: ContentfulImage
  sliderImageBottom: ContentfulImage
  slug: string
  pageOrder: number
  parentMoment: Moment
  title: string
  descriptionImage?: ContentfulImage
  authorAndDate: string
  text: RichTextJson
  capsulesTitle: string
  capsules: Video[]
  socialMediaShareMessage: string
}

export type HowItWorksType = {
  imageDesktop: ContentfulImage
  imageTablet: ContentfulImage
  imageMobile: ContentfulImage
  steps: {
    contentful_id: string
    icon: {
      file: FileType
    }
    text: RichTextJson
  }[]
}

export type BannerFluidType = {
  name: string
  videoDesktopTablet?: {
    url: string
  }
  videoMobile?: {
    url: string
  }
  link?: string
  imageDesktop?: {
    url: string
  }
  imageTablet?: {
    url: string
  }
  imageMobile?: {
    url: string
  }
  backgroundColor?: string
  contentful_id: string
}

type TextColor = 'red' | 'white' | 'black' | 'blue' | 'green'

export type BannerDiscountLabelColor =
  | 'green'
  | 'red'
  | 'blue'
  | 'orange'
  | 'black'
  | 'red-neon'
  | 'green-neon'
  | 'blue-neon'
  | 'red-neon-outline'
  | 'pink-neon-outline'
  | 'yellow'

export type BannerText = {
  textAlignment: 'left' | 'right' | 'center'
  contentAlignment: 'end' | 'start' | 'center'
  referentialPrice: RichTextJson
  referentialPriceColor?: TextColor
  referentialPriceStrikethrough?: boolean
  discount?: number
  discountType?: 'percentage' | 'amount'
  discountLabelText?: 'DESDE' | 'HASTA' | 'EXTRA'
  discountLabelColor?: BannerDiscountLabelColor
  preTitle: RichTextJson
  preTitleColor?: TextColor
  subtitleBanner: RichTextJson
  subtitleColor?: TextColor
  titleBanner: RichTextJson
  titleColor?: TextColor
  promotionalCode: RichTextJson
  promotionalCodeColor?: TextColor
  promotionalCodeBackgroundColor?: TextColor
  alcoholicBeverageBlock?: string
  hashtag?: RichTextJson
  hashtagColor?: TextColor
  actionSlugBanner: {
    actionSlug: string
  }
  buttonColor: 'red' | 'white' | 'black' | 'white-red' | 'blue'
  buttonTextBanner: RichTextJson
  buttonType: 'default' | 'outline' | 'text'
}

export interface MlBanner extends BannerText, TagManagerEventData {
  id: string
  name: string
  layout: 'text-image' | 'image-text-image' | 'image-text'
  backgroundImageDesktop: ContentfulImage
  backgroundImageMobile: ContentfulImage
  backgroundImageTablet: ContentfulImage
  productImagesDesktop: ContentfulImage[]
  productImageTablet: ContentfulImage
  productImageMobile: ContentfulImage
  dropdownButtonText?: RichTextJson
  dropdownButtonPosition?: 'left' | 'right'
  dropdownContent?: RichTextJson
}

export enum HeadingType {
  DEFAULT = 'Default',
}

export type Heading = {
  content: RichTextJson
  type: HeadingType
}

export enum CountdownType {
  DEFAULT = 'Default',
}

export type Countdown = {
  type: CountdownType
  targetDate: string
  displayDays?: boolean
  title?: string
  subtitle?: string
  colorScheme?: 'dark' | 'light'
  backgroundMobile?: {
    url: string
  }
  backgroundTablet?: {
    url: string
  }
  backgroundDesktop?: {
    url: string
  }
  backgroundMobileAlignment?: 'left' | 'center' | 'right'
  backgroundTabletAlignment?: 'left' | 'center' | 'right'
  backgroundDesktopAlignment?: 'left' | 'center' | 'right'
  slug?: string
}

export type ContentfulMLVideo = {
  videoPoster?: FileType
  title?: string
  video: FileType
  videoDescription?: string
}

export type MlTextWithIcon = {
  contentful_id: string
  title?: string
  text: RichTextJson
  icon: { url?: string } & ContentfulImage
  value?: number
}

export enum PageType {
  VAMOS_POR_OTRA = 'vamos-por-otra',
  PAGE = 'page',
  MOMENT = 'moment',
  MOMENT_RECIPES = 'moment-recipes',
  HOME = 'home',
  PROMOTIONS = 'promotions',
}

export type ClientSideMlTextWithIcon = {
  title: string
  text: RichTextJson
  textColor?: string
  icon?: FileType
}

export type ClientSideMlHero = {
  text: string
  heroType: string
  textColor: string
  buttonText: string
  buttonSlug: string
  backgroundColor: string
  image: {
    url: string
  }
  callToActionImageMobile: {
    url: string
  }
  callToActionImageTablet: {
    url: string
  }
  callToActionImageDesktop: {
    url: string
  }
}

export type ClientSideMlVideo = {
  sys: {
    id: string
  }
  title: string
  videoDescription: string
  video: {
    url: string
  }
  videoPoster: {
    url: string
  }
}

export type ClientSideOrNews = {
  sys: {
    id: string
  }
  slug: string
  sliderTitle: string
  sliderDescription: string
  sliderImage: {
    url: string
  }
  sliderImageBottom: {
    url: string
  }
  sliderRichTextDescription: {
    json: Document
  }
  sliderDescriptionColorBold?: string
  sliderButtonColor?: 'red' | 'white' | 'black' | 'blackInverted' | 'blue' | 'gray'
}

export type ClientSideProductSlide = {
  sys: {
    id: string
  }
  backgroundColor?: string
  title?: string
  subtitle?: string
  showPrice: boolean
  addSpace: boolean
  description?: {
    json: Document
  }
  image: {
    url: string
    sys: {
      id: string
    }
  }
  showAddButton: boolean
  showDetailsButton: boolean
  bodyColor: string
  priceColor?: string
  originalPriceColor?: string
  product: {
    sku: string
  }
}

export type OrRecipeSlide = {
  name: string
  title?: string
  imageMobile?: {
    url: string
  }
  imageTablet?: {
    url: string
  }
  imageDesktop?: {
    url: string
  }
  backgroundColor?: string
  description?: string
  primaryTextButton?: string
  primarySlugButton?: string
  secondaryTextButton?: string
  secondarySlugButton?: string
}

export interface ClientSidePromoSlide {
  sys: {
    id: string
  }
  actionSlug: string
  imageMobile: {
    url: string
  }
  imageTablet: {
    url: string
  }
  imageDesktop: {
    url: string
  }
  actionSlugLong: string
}

export interface ClientSideRecipeList {
  sys: {
    id: string
  }
  slug: string
  sliderTitle: string
  sliderDescription: string
  sliderImage: {
    url: string
  }
  backgroundColorCard?: string
  textColorCard?: string
}

export interface ClientSideBrandsList {
  sys: {
    id: string
  }
  brandsCollection: {
    items: [
      {
        url: string
      },
    ]
  }
  background: {
    url: string
  }
}

export type ClientSideRecipeSlide = {
  itemsCollection: {
    items: [
      {
        name: string
        title?: string
        image?: string
        backgroundColor?: string
        description?: string
        showButton: boolean
        textButton?: string
        slugButton?: string
      },
    ]
  }
}

export interface ClientSideHowItWorks {
  sys: {
    id: string
  }
  name: string
  backgroundColor: string
  stepsCollection: {
    items: [
      {
        sys: {
          id: string
        }
        title: string
        text: {
          json: Document
        }
        icon: {
          url: string
        }
      },
    ]
  }
  imageMobile: {
    url: string
  }
  imageTablet: {
    url: string
  }
  imageDesktop: {
    url: string
  }
}

export interface ClientSideFilteredList {
  attributeToFilter: string
  filtersCollection: {
    items: {
      label: string
      filter: string
    }
  }
  itemsCollection: {
    items: {
      sku: string
      categoryName: string
    }
  }
}

export interface CollapseItem {
  title: string
  content: RichTextJson
}

export type FileType = {
  url: string
}

export interface AtRibbonMessage {
  message: RichTextJson
  url: string
}
