import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import PromoList, { PromoCardType } from '../../components/PromoList'
import promoListQuery, { QueryPromo, QueryResult } from '../../graphql/promoListQuery'
import { BlockPadding, GoogleTagManagerEventType } from '../../graphql/contentfulTypes'
import useQuery from '../../hooks/useQuery'
import { SimpleBlockWrapper } from '../../utils/blocks'
import { sendViewPromotionEvent, sendSelectPromotionEvent } from '../../utils/events'

interface Props {
  isLiqueursLanding?: boolean
  listId: string
  className?: string
  themeId?: string
  showRibbon?: boolean
  useBackground?: boolean
}

type StateData = {
  title?: string
  titleType?: 'h1' | 'h2'
  type: PromoCardType
  items: QueryPromo[]
} & BlockPadding

const Promotions = ({ isLiqueursLanding, className, listId, themeId }: Props) => {
  const [promosData, setPromosData] = useState<StateData>({
    type: 'promo-small',
    items: [],
  })
  const [isLoading, setLoading] = useState(true)
  const [renderLists, setRenderLists] = useState(true)

  const { data, loading, error } = useQuery<QueryResult>(promoListQuery, { variables: { listId } })

  useEffect(() => {
    if (data && !loading) {
      const block = data?.orList

      if (!block) return setRenderLists(false)

      setPromosData({
        type: (block?.blockType as PromoCardType) ?? 'promo-small',
        items: block?.itemsCollection?.items ?? [],
        title: block?.title,
        titleType: block?.titleType ?? 'h2',
        paddingTop: block.paddingTop,
        paddingBottom: block.paddingBottom,
      })

      const promotionItems =
        block?.itemsCollection?.items.filter(
          (item) =>
            item.googleTagManagerEventType === GoogleTagManagerEventType.PROMOTION && !!item.googleTagManagerEventValue,
        ) ?? []

      if (promotionItems.length)
        sendViewPromotionEvent(
          promotionItems.map((item) => ({
            item_id: item.googleTagManagerEventValue ?? '',
            item_name: item.googleTagManagerEventValue ?? '',
            creative_name: item.promoTitle,
          })),
        )
      setLoading(false)
    }
  }, [data, loading])

  if (!renderLists || error !== undefined) return null

  return (
    <SimpleBlockWrapper {...promosData}>
      <PromoList
        isLiqueursLanding={isLiqueursLanding}
        className={className}
        items={
          promosData?.items.map((promo) => ({
            id: promo.sys.id,
            title: promo?.promoTitle || '',
            titleType: promo.titleType ?? '',
            subtitle: promo?.promoSubtitle || '',
            image: promo?.image?.url || '',
            description: promo?.promoDescription || '',
            slug: promo?.products ? `/search?skus=${promo.products}` : promo?.actionSlugLong || promo?.promoSlug || '',
            googleTagManagerEventType: promo.googleTagManagerEventType,
            googleTagManagerEventValue: promo.googleTagManagerEventValue,
            products: promo?.products,
          })) ?? []
        }
        titleType={promosData?.titleType ?? 'h2'}
        type={promosData?.type ?? 'promo-large'}
        title={promosData?.title}
        titleColor={data?.orList?.titleColor}
        titleBorderColor={data?.orList?.titleBorderColor}
        titleBorderColorShadowNeon={data?.orList?.titleBorderColorShadowNeon}
        centerTitle={data?.orList?.centerTitle}
        onCardClick={(promo, _, idx) => {
          if (
            promo.googleTagManagerEventType === GoogleTagManagerEventType.PROMOTION &&
            !!promo.googleTagManagerEventValue
          )
            sendSelectPromotionEvent({
              itemId: promo.googleTagManagerEventValue,
              itemName: promo.googleTagManagerEventValue,
              creativeName: promo.title,
              position: `${idx ? idx + 1 : 0}`,
            })

          if (promo.slug)
            navigate(promo.slug?.startsWith('/') ? promo.slug : `/${promo.slug}`, {
              state: { themeId },
            })
        }}
        isLoading={isLoading}
        {...data?.orList}
      />
    </SimpleBlockWrapper>
  )
}

export default Promotions
