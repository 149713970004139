import { ClientSideBlockQueryResult, TagManagerEventData } from './contentfulTypes'
import { MlCardPromoFragment, OrListFragment } from './fragments'

export interface QueryPromo extends TagManagerEventData {
  promoTitle: string
  promoSubtitle: string
  promoDescription: string
  promoSlug: string
  products: string[]
  actionSlugLong: string
  titleType: string
  image: {
    url: string
  }
  sys: {
    id: string
  }
}

export type QueryResult = ClientSideBlockQueryResult<QueryPromo>

export default `
  query PromoListQuery($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment
      itemsCollection {
        items {
          ...MlCardPromoFragment
        }
      }
    }
  }
  ${OrListFragment}
  ${MlCardPromoFragment}
`
